.historyGrid {
    .expandAll {
      cursor: pointer;
      padding: 10px 0;
    }
  }
  
  .historyDetails {
    padding: 16px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  
  .message {
    display: table;
    border-collapse: collapse;
    width: 90%;
    padding: 15px 3px;
  }
  
  .messageItem {
    display: table-row;
    border-bottom: .5em solid transparent;
  }
  
  .messageItemTitle {
    display: table-cell;
  }
  
  .titleWrapper {
    display: flex;
  }
  
  .typeName {
    display: block;
    word-break: break-all;
  }
  
  .dotted {
    flex-grow: 1;
    display: block;
    min-width: 20px;
    margin: 0 10px;
    border-bottom: 1px dotted var(--text-primary);
    height: 12px
  }
  
  .arrow {
    padding: 0 10px;
    display: table-cell;
    width: 15px;
  }
  
  .prevValue {
    white-space: pre-wrap;
    max-width: 250px;
  }
  
  .newValue {
    white-space: pre-wrap;
    display: table-cell;
    max-width: 250px;
  }
  
  