.testingResultsContainer {
    margin-bottom: 40px;

    .headerContaier {
        display: flex;
        align-items: center;
        font-family: "Roboto";
        font-size: 18px;
        font-weight: 500;
        line-height: 17px;
        gap: 12px;
        color: var(--text-secondary);

        svg {
            cursor: pointer;
        }
    }
    .resultsContentWrapper {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .testingParams {
            display: flex;
            flex-direction: column;
            padding: 16px 12px;
            border-radius: 8px;
            border: 1px solid var(--color-divider);
            gap: 24px;
            width: 470px;

            h3 {
                font-family: "Roboto";
                font-size: 16px;
                font-weight: 500;
                line-height: 17px;
                color: var(--text-secondary);
                margin: 0;
            }
            .params {
                .paramsHeader {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 12px;
                    background-color: #F8F9FA;
                    border-top: 1px solid var(--color-divider);
                    border-bottom: 1px solid var(--color-divider);

                    span {
                        color: #495058;
                        font-family: "Roboto";
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 15px;
                        width: 32%;
                    }
                }
                .paramsValues {
                    width: 100%;
                    .emptyParams {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding: 10px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        color: #212121;
                        font-family: "Roboto";
                    }
                    .paramsRow {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 8px 16px;
                        width: 100%;

                        span{
                            width: 32%;
                            color: #212121;
                            font-family: "Roboto";
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
            .editButton {
                width: 100%;
                border: 1px solid var(--color-primary);
                padding: 8px 12px;
                color: var(--color-primary);
                font-family: "Roboto";
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
            }
        }

        .testAnswer {
            flex: 1;

            .type-cell {
                width: 100%;
                display: table;
                table-layout: fixed;
                padding: 0 15px;

                &__wrap {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                    span {
                        display: inline;
                        min-width: 200px;
                    }
                }
            }
        }

        .errorWrapper {
            display: flex;
            flex-direction: column;
            width: 590px;
            gap: 4px;

            .errorLine {
                display: flex;
                align-items: center;
                padding: 4px 10px 4px 10px;
                border-radius: 4px;
                background-color: rgba(253, 232, 231, 0.90);
                border-left: 6px solid #EF4444;

                p {
                    margin: 0 0 0 10px;
                    font-family: "TT Hoves";
                    color: #EF4444;
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .errorContent {
                width: 100%;
                border-radius: 4px;
                background-color: #FBE8E7;
                padding: 16px;

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    margin: 0;

                     li {
                        color: #212121;
                        font-family: "Roboto";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 12px;
                        margin: 0;
                    }
                }
            }
        }
    }
}