.menu_icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    text-align: right;
    padding-right: 6px;

    span {
        cursor: pointer;
    }
}

.list_item{
    padding: 2px 1em;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    &:hover{
        background-color: var(--background-plate-nested);
    }
    span {
        svg {
            width: 10px;
            height: 17px;
            margin-left: 2px;
            position: relative;
            top: 4px;
        }
    }
}

.hidden {
    display: none;
}

.checkbox {
    border: 1px solid #495058;
    width: 14px;
    height: 14px;
}
.checkbox_label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #495058;
    height: 15px;
}
.actions{
    display: flex;
    align-items: center;
    padding: 9px 7px;
  
    button{
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      padding: 8px;
    }
}

.column_list {
    max-height: 296px;
    max-width: 250px;
}

.searchColumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px 8px 8px;
    height: 49px;
    max-width: 250px;
    margin-bottom: 8px;
    div {
        width: 205px;
        border-radius: 3px;
    }
    input {
        width: 100%;
        padding: 8px 10px;
        font-family: 'Raleway', 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        color: #495057;
    }
}

.filterIcon {
    cursor: pointer;
    svg {
        height: 17px;
        width: 14px;
    }
}
