$primary: #6893ff;
$secondary: #757575;
$tertiary: #2f69fb;
$info: #0058e9;
$success: #278200;
$warning: #f5a600;
$error: #d51923;
$light: #757575;
$body-text: #424242;
$body-bg: #ffffff;
$heading-text: #272727;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #ffffff;
$base-text: #424242;
$base-bg: #fafafa;
$hovered-text: #424242;
$hovered-bg: #ececec;
$selected-text: #ffffff;
$selected-bg: #1274ac;
$kendo-button-text: #424242;
$kendo-button-bg: #f5f5f5;
$kendo-flat-button-hover-opacity: 4%;
$kendo-flat-button-active-opacity: 6%;
$kendo-flat-button-selected-opacity: 8%;
$link-text: #1274ac;
$link-hover-text: #0f6190;
$kendo-input-clear-value-opacity: 80%;
$series-a: #1274ac;
$series-b: #ffe162;
$series-c: #4cd180;
$series-d: #0074e6;
$series-e: #9b4fe6;
$series-f: #ff80ac;
$scheduler-event-bg: #1274ac;
$scheduler-event-selected-bg: #0f6190;
$pager-number-text: #424242;
$pager-number-hover-bg: #ececec;
$pager-number-hover-text: #424242;
$pager-number-selected-bg: #1274ac;
$pager-number-selected-text: #ffffff;
$kendo-checkbox-checked-text: #ffffff;

$kendo-border-radius-md: 3px;
$kendo-border-radius-sm: 3px;
$kendo-border-radius-lg: 3px;
