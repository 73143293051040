.date_wrapper{
  max-width: 350px;
  padding: 1em;
  .date_input {
    &:first-of-type{
      margin-bottom: 16px;
    }

    input{
      padding: 8.5px 14px;
    }
    label{
      transform: translate(14px, 8.5px) scale(1);
    }
    label.Mui-focused,
    label.MuiFormLabel-filled{
      transform: translate(14px, -9px) scale(.75);
    }
  }

}
