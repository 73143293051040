.component-item{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  &__breadcrumbs{
    width: 100%;
  }

  &__tabs{
    width: 100%;
  }

  &__form{
    max-width: 620px;
  }

  &__components{
    margin-top: 80px;
  }

  .title{
    color: var(--text-secondary);
    font-size: 18px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 6px;
    span {
      text-transform: uppercase;
      padding: 4px 9px;
      background-color: #BBF7D0;
      color: #176635;
      border-radius: 5px;
      margin-left: 10px;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .linked-components {
    margin: 2em 0 3em;
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: .5em 0;
      button {
        text-transform: none;
      }
    }
    table {
      width: 100% !important;
    }
    .k-grid-header-menu.k-grid-column-menu {
      margin: 0;
      text-align: right;
    }

  }

  .component-options {
    margin: 2em 0 3em;
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: .5em 0;
      margin-top: 20px;
      button {
        text-transform: none;
      }

      .title {
        display: flex;
        align-items: center;
      }

      div.title span {
        text-transform: uppercase;
        padding: 4px 9px;
        background-color: #BBF7D0;
        color: #176635;
        border-radius: 5px;
        margin-left: 10px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
      }

    }
    table {
      width: 100% !important;
    }
    .k-grid-header-menu.k-grid-column-menu {
      margin: 0;
      text-align: right;
    }
    .actions {
      display: flex;
      align-items: center;
      padding-right: 24px;
      &>button{
        margin-right: 12px;
      }
    }
  }
}

.linked-components, .component-options{
  .option-grid-actions {
    justify-content: flex-end;
    font-size: 14px;

    & > li, li:hover, li:focus, li:active {
      color: #ffffff;
      background-color: var(--color-primary) !important;
      border: 1px solid var(--color-primary);
      border-radius: 3px;
      padding: 6px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

      .k-menu-expand-arrow {
        display: none;
      }

      .k-menu-link {
        padding: 0;
        gap: 0;

        .k-icon {
          width: 20px;
          height: 20px;
        }
      }

    }
  }
}

.component-links-grid{
  .k-grid-header{
    padding-inline-end: 0;
    .k-header.k-grid-header-sticky{
      border-left: none;
      border-right: none;
      background-color: var(--background-plate-nested)
    }
    .k-filterable{
      padding-right: 40px;
    }
  }
  .k-grid-header-wrap{
    margin: 0;
    border-right: none;
  }
  .k-grid-content{
    overflow-y: hidden;
  }
  .k-grid-content-sticky{
    border: none;
  }
}

.k-menu-group-md .k-menu-item{
  font-size: 14px;
}

.component-ref-title > div {
    width: unset;
    min-width: unset;
}
