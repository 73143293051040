.list_item{
  padding: 2px 1em;
  &:hover, &:focus-within{
    background-color: var(--background-plate-nested);
  }
  cursor: pointer;
}

.checkbox {
  border: 1px solid #495058;
  width: 14px;
  height: 14px;
}

.checkbox_label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #495058;
}

.actions{
  display: flex;
  flex-direction: row;
  padding: 1em;
  justify-content: space-between;

  button{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    &:first-of-type{
      margin-right: .6em;
    }
  }
}

.filter{
  display: flex;
  cursor: pointer;
  svg{
    opacity: 0;
    pointer-events: none;
  }
}

.header{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  //&>div {
  //  white-space: pre-wrap;
  //}
  .filter{
      padding: 0 8px;
  }
  &:hover {
    .filter{
      svg{
        opacity: 1;
      }
    }
  }
  &.filtered{
    .filter{
      svg{
        opacity: 1;
      }
    }
  }
}

.version{
  font-size: 11px;
  line-height: 11px;
  background: rgb(234, 235, 240);
  display: inline-flex;
  padding: 4px 6px;
  border-radius: 4px;
  margin-left: 4px;
}


