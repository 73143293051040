.component-item-confirm-dialog {
  .dialog-title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 18px 16px;
    border-bottom: 1px solid var(--color-divider-nested);
  }

  .dialog-content {
    padding-right: 15px;
    padding-left: 15px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    &__title {
      padding: 11px 0;
      font-weight: 500;
      line-height: 16px;
    }

    &__wrapper{
      max-height: 50vh;
      overflow-y: auto;
      padding: 1em 0;
    }

    &__message {
      display: table;
      border-collapse: collapse;
      width: 90%;
      padding: 15px 3px;

      &__item {
        display: table-row;
        border-bottom: .5em solid transparent;

        &.error {
          .arrow,
          .prev-value,
          .new-value {
            color: var(--text-danger);
          }
        }

        .title {
          display: table-cell;

          .title-wrapper {
            display: flex;
            .type-name{
              display: block;
              word-break: break-all;
            }
          }

          .dotted {
            flex-grow: 1;
            display: block;
            min-width: 20px;
            margin: 0 10px;
            border-bottom: 1px dotted var(--text-primary);
            height: 12px
          }
        }

        .arrow {
          padding: 0 10px;
          display: table-cell;
          width: 15px;
        }

        .prev-value {
          white-space: pre-wrap;
          word-break: break-word;
          max-width: 250px;
        }

        .new-value {
          white-space: pre-wrap;
          word-break: break-word;
          display: table-cell;
          max-width: 250px;
        }
      }
    }

    &__message {

    }
  }

  .dialog-buttons {
    padding: 15px 16px 14px;
    border-top: 1px solid var(--color-divider-nested);
  }

}
