.app_pagination{
  position: sticky;
  bottom: 15px;
}

.with_buttons_wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

}

.with_page_size {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: var(--text-secondary)
}

.current_record{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--text-secondary)
}
