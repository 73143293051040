.grid-cell {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    p {
        margin: 0;
        text-overflow: ellipsis;
    }
    .version{
        font-size: 11px;
        line-height: 11px;
        background: rgb(234, 235, 240);
        display: flex;
        padding: 4px 6px;
        border-radius: 4px;
        margin-left: 4px;
    }
}
.amountCell {
    justify-content: flex-end;
    padding-right: 30px;
}
