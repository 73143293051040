.spec-item-header{
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px 22px !important;
  background: var(--background-plate-nested);
  border-bottom: 1px solid var(--color-divider);

  .title{
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;

      &.type{
        font-size: 13px;
        color: #6C757D;
      }

      &.version{
        padding: 4px 6px;
        border-radius: 3px;
        font-weight: 500;
        font-size: 11px;
        line-height: 11px;
        color: var(--text-secondary);
        background: #EAEBF0
      }
    }
  }

  .buttons{
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;

    button.icon-button,
    .icon-button button{
      position: relative;
      display: flex;
      height: 33px;
      width: 33px;
      align-items: center;
      justify-content: center;
      min-width: auto;
      padding: 0;
      color: #5F6D7E;
      background: var(--background-color);
      border: 1px solid var(--border-color);
      box-shadow: none;
      svg, .k-icon{
        height: 14px;
        width: auto;
      }

      &.active:after{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #2296F3;
        border: 2px solid #F8F9FA;
        border-radius: 50%;
        top: -5px;
        right: -5px;
      }
    }
  }
  .price{
    padding: 0 10px;
  }
}

