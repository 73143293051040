.fx_label {
  z-index: 10;
}

.fx_wrapper {
  float: right;
  cursor: pointer;
}

.fx_style {
  margin-left: 6px;
  color: var(--text-gray);
  cursor: pointer;

  &:hover{
    color: var(--text-blue);
  }
}

.fx_active{
  color: var(--text-blue);
  display: inline-flex;
  vertical-align: middle;
  padding: 0 1px;
  & > span {
    display: block;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.fx_icon_style {
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.readonly{
  cursor: default;
}
