.scroll-to-top{
  display: flex;
  position: sticky;
  bottom: 24px;
  right: 24px;
  justify-content: flex-end;
  z-index: 99999;

  button.icon-button{
    display: flex;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    min-width: auto;
    padding: 0;
    color: #FFFFFF;
    background: rgba(95, 109, 126, 0.8);
    border-radius: 3px;
    border: 1px solid var(--border-color);
    box-shadow: none;
  }
}
