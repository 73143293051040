.new-item-dialog {

  form {
    width: 700px;

    .MuiGrid-item {
      max-width: 100%;
    }
  }

  .dialog-title{
    font-size: 28px;
    font-weight: 400;
  }

  .hint{
    margin-bottom: 20px;
    font-size: 11px;
    color: var(--text-primary);
    span{
      display: inline-block;
      border: 1px solid #9fa6ad;
      background: #e3e6e8;
      padding: 2px 5px;
      margin: 0 2px;
      border-radius: 3px;
      font-size: 10px;
      text-shadow: 1px 1px 0px white;
      box-shadow: 0 1px 1px rgb(12 13 14 / 15%), inset 0 1px 0 0 #ffffff;
    }
  }

  .form-buttons{

    justify-content: flex-end;
  }

  .form-field{
    margin-bottom: 26px;

    &.form-field-error{
      margin-bottom: 8px;
    }
  }

  legend{
    transform: translate(-14px, 7px) scale(0.75);
  }
}

.codeEditor {
  .monaco-scrollable-element {
    left: 0 !important;
  }
}
