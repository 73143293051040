.settingsCard{
  width: 100%;
  margin-bottom: 15px;
}

.settingsBody{
  padding: 10px;
}

.settingsTitle {
  background: var(--background-plate-nested);
  padding: 15px 16px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  border-bottom: 1px solid var(--color-divider-nested);
  margin: 0;
}

.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid var(--color-divider-nested);
  &:last-of-type{
    border-bottom: none;
  }

  span {
    font-weight: bold;
  }
}

.rowTitle{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 8px;
}

.settingsBtn {
  text-transform: none !important;
  width: 125px;
  margin-left: 8px !important;
  &:first-of-type{
    margin-left: 0 !important;
  }
}
