.specification-item-calculation {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  //height: 100%;
  &.empty{
    height: 100%;
  }

  .type-cell {
    width: 100%;
    display: table;
    table-layout: fixed;
    padding: 0 24px;

    &__wrap {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        display: inline;
        min-width: 200px;
      }
    }

  }

  .info-cell {
    vertical-align: middle;

    .k-icon {
      cursor: pointer;
    }

    .info {
      opacity: 0;
    }

    &:hover {
      .info {
        opacity: 1;
      }
    }

    &-button {
      width: 24px;
      height: 16px;
      margin-left: 10px;
      margin-bottom: 2px;
      border: none;
      background: none;
      box-shadow: none;

      &:hover {
        &:before {
          opacity: 0;
        }
      }
    }

  }

  .k-grid td {
    border-left: none;
    border-bottom: 1px solid #DEE2E6;
    padding: 21px 16px;

  }

  .k-master-row.k-alt {
    background: inherit;
  }

  &__content {
    padding-top: 16px;
    width: 100%;
  }

  &__actions{
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: var(--background-color);
  }

}
