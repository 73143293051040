.newConfigurator {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-template-rows: 70px auto;
  gap: 10px;
  grid-template-areas:
    "header"
    "grid";
  height: 100%;
  width: 100%;

  &__header {
    grid-area: header;
  }
  &__grid {
    grid-area: grid;
    height: 100%;
    overflow: hidden;

    table {
      width: 100% !important;

      thead tr:hover .actions-menu {
          background: #F8F9FA;
      } 

      th {
        background: #f8f9fa;
        border-bottom: 1px solid #e9ecef;
        border-top: 1px solid #e9ecef;
        padding: 7px 24px;
        font-weight: 700;
        color: rgba(0,0,0,.54);
        font-size: 12px;
      }

      tr td {
          padding: 4px 24px;
      }

      tbody tr td:last-of-type {
        text-align: center;
        left: 0;
        right: 0;
        position: sticky;
        z-index: 2;
        background-color: #fff;
      }

      tr:hover td:last-of-type {
        background-color: rgb(237 237 237);
      }

      .k-dropdown-button button{
        padding: 2px;
      }
    }
    .grid-wrapper{
      overflow: hidden;
      height: calc(100vh - 253px);

      .k-grid-md {
        height: 100%;
      }

      .actions-menu {
        position: sticky;
        border-right: none;
        border-left: none;
        background: #F8F9FA;
      }
    }
    .k-child-animation-container {
      height: 100%;
    }
    .k-grid-header-menu.k-grid-column-menu {
      margin: 0;
      text-align: right;
    }
    .k-card {
     height: 100%;
    }

    .spec-grid {
      height: calc(100vh - 274px);
    }

    .spec-pager-wrapper {
      margin-bottom: 0;
      position: absolute;
      bottom: 15px;
      width: 100%;
    }
  }
}

.k-grid-table{
  td {
    cursor: pointer;
  }
}



