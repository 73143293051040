@import '~@progress/kendo-theme-material/scss/grid/index';

.k-grid{
  border: none;

  table{
    th{
      padding: 8px 24px;
    }
    td {
      border-bottom: 1px solid #E9ECEF;
    }
    tr{
      cursor: pointer;
    }
  }

  &.non-clickable{
    table{
      tr{
        cursor: default;
      }
    }
  }
}

.k-grid-header {
  //padding-inline-end: 0;
  //-webkit-padding-end: 0;
}

.k-grid-header .k-header {
  border-bottom: 1px solid #E9ECEF;
  border-top: 1px solid #E9ECEF;
  background: #F8F9FA;
  & > .k-cell-inner {
    margin: -8px -8px;
    & > .k-link {
      padding: 8px 24px;
    }
  }
}

.actions-menu{
  .k-cell-inner{
    justify-content: flex-end;
    padding-right: 10px;
    margin-right: 0;
    margin-left: 0;
  }
  .k-link{
    flex: 0;
    padding-left: 14px
  }
}

.universal-grid  {
  div.k-grid-header {
    border-bottom: 1px solid #E9ECEF;
    border-top: 1px solid #E9ECEF;
    background: #F8F9FA;
    padding-right: 0;
    .k-header{
      border: none;
      background: transparent;
    }
  }

  div.k-grid-header-wrap {
    border: none;
  }

  div.k-grid-content{
    overflow-y: auto;
  }

  div.k-grid {
    td{
      padding: 4px 24px;
    }
    .k-dropdown-button button{
      padding: 2px;
    }
  }

  .grid-cell{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions-menu .k-cell-inner {
    justify-content: flex-end;
  }

  .k-grid-header .k-header.k-grid-header-sticky{
    background: #F8F9FA;
    //padding-right: 20px
  }
  .k-grid .k-master-row .k-grid-content-sticky {
    border-left: none;
    border-right: none;
  }

  .numeric-cell{
    text-align: right;
    justify-content: flex-end;
  }
}
