.item-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;

  .form-tabs,
  .action-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    .more-menu {
      padding: 5px;
      margin: -9px 0 -5px 0;
      color: inherit;
    }
  }

  .tab {
    padding: 16px;
    border-bottom: 2px solid #e9ecef;
    cursor: pointer;

    &.active {
      color: #2f69fb;
      border-bottom: 2px solid #2f69fb;
    }
  }
  .switch {
    border-bottom: 2px solid transparent;
    padding-right: 16px;
    .k-label {
      cursor: pointer;
    }
    .k-switch {
      margin-left: 8px;
    }
  }
}
