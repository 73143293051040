.name_cell {
    width: 100%;
    min-width: 150px;
    display: table;
    table-layout: fixed;
    position: relative;

    &> div{
        display: table-cell;
    }

    .k-icon {
        width: 24px;
    }
    &__wrap{
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        padding-right: 30px;
        max-width: fit-content;

        span{
            display: inline;
            min-width: 240px;
        }
    }
    &__icon{
        text-align: left;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;

        .info{
            width: 16px;
            height: 16px;
            display: inline-flex;
            margin: 0 10px;
            color: #1976D2;
            cursor: pointer;
            vertical-align: text-top;
            svg{
                width: 100%;
                height: 100%;
            }
        }
    }

    &:hover {
        .name_cell__icon{
            opacity: 1;
        }
    }
}

.info_popover {
    width: 350px;

    &__wrapper {
        padding: 8px 0 8px 0;
    }

    &__title {
        padding: 16px;
        font-weight: 500;
        background: #F8F9FA;
        border-bottom: 1px solid #DEE2E6;
    }

    table {
        border-collapse: collapse;

        td {
            border-bottom: 1px solid #DEE2E6;
            padding: 8px 20px 8px 16px;
            vertical-align: top;
            white-space: pre-wrap;
            word-break: break-word;

        }

        tr:last-child > td {
            border-bottom: none;
        }
    }
}
