.status-message {
  display: flex;
  align-items: center;
  padding: 14px;

  span {
    margin-left: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: var(--text-secondary);
  }

  &.status-message-information{
    color: #0A87C0;
  }
  &.status-message-warning{
    color: #CD9200;
  }
  &.status-message-error{
    color: #FE6363;
  }
}
