.sticky-download {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 10;
  margin: 0 -16px;

  .wrapper {
    padding: 12px 24px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0px 0px 0px 6px;

    &.no-style {
      padding: 0 24px;
      background: transparent;
      height: 0
    }

    &.sticky {
      padding: 12px 24px;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      height: auto;
    }
  }
}
