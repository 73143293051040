

.status-badge {
  display: inline-flex;
  padding: 4px 6px;
  border-radius: 3px;
  //text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  color: var(--text-secondary);

  &-draft,
  &-archive{
    background: #EAEBF0;
  }
}


