.multiple-download-dialog{
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-heading);

  .dialog-title{
    font-weight: 500;
    padding: 16px 16px 0;
  }

  .dialog-content{
    padding: 10px 16px;
    color: inherit;
    label{
      margin: -6px 0 0 -9px;

    }
  }

  .dialog-actions{
    padding: 0 16px 16px;
    button{
      width: 100%;
    }
  }
}
