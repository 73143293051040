.app_fade{
  width: 100%;
  height: 100%;
  &> div{
    //height: 100%;
  }
}

.entered{
  overflow: visible;
}
