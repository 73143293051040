.k-menu-group-sm .k-menu-link {
  padding: 0;
}

.k-menu-group .k-menu-item.k-focus {
  background: #ffffff !important;
  font-weight: 600;
}


.action-item {
  border-bottom: 1px solid #E9ECEF;
  width: 100%;

  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 12px 16px;
    gap: 10px;
    min-width: 200px;
  }
}
