.login-wrapper{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  form{
    width: 364px;
  }

  .title{
    padding: 10px;
    & > div > span{
      font-size: 14px;
    }
  }

  .constent{
    padding-top: 0;
  }

  .actions{
    justify-content: flex-end;
    padding: 0 20px 20px 20px ;
    button{
      text-transform: none;
    }
  }

  .form-field{
    margin-bottom: 16px;

    &.form-field-error{
      margin-bottom: 0;
    }
  }

  .login-form{
    position: relative;
    width: 364px;
    .logo {
      position: absolute;
      left: -9px;
      top: -40px;
    }
    .noRolesError {
      color: #D32F2F;
    }
  }
}
