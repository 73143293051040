.component-testing-dialog {
    .loadingBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 329px;
        height: 108px;
        padding: 20px;

        p {
            margin: 18px 0;
            color: var(--text-secondary);
            text-align: center;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
        }
    }

   .dialog-title, .dialog-content {
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
   }

   .infoContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #F4F5F6;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 17px; 
    color: var(--text-secondary);
    margin: 4px 16px;
    border-radius: 4px;

    p {
        margin: 0;
    }
   }

   .formContainer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    // width: 700px;

    .labelContainer {
        display: flex;
        align-items: center;
        
        .label {
            width: 210px;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            font-family: "Roboto";
            color: var(--text-primary);
            margin: 0;
        }
    }

    .inputsContainer {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .inputsRow {
            display: flex;
            gap: 12px;
            width: 100%;
            height: 33px;
            margin-bottom: 17px;

            .inputContainer {
                display: flex;
                flex-direction: column;
                gap: 3px;

                p.errorMessage {
                    color: #EF4444;
                    font-family: "Roboto";
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12.5px;
                    margin: 0;
                }

                input, select {
                    width: 200px;
                    border-radius: 3px;
                    padding: 8px;
                    color: var(--text-primary);
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    box-sizing: border-box;
                }
            }
            .deleteIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 33px;
                border-radius: 4px;
                background-color: #F4F5F6;
                cursor: pointer;
            }
        }
    }

    .addButton {
        gap: 4px;
        padding: 8px 12px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--color-primary);
        color: #1976D2;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
    }
   }

   .testing-buttons {
    border-top: 1px solid var(--color-divider);
    margin: 0 16px;
    padding: 20px 0 16px;
   }

   .testDialogSelect {
    width: 200px;
    height: 35px;
   }
}
