.help-dialog{
  //position: relative;
  .content{
    padding: 0 24px 24px;
  }
  .close{
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
