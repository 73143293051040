.breadcrumbs-card{
  padding: 10px 16px;
}

.breadcrumbs{
  display: flex;
  gap: 8px;
  align-items: center;

  a, .item{
    display: flex;
    text-decoration: none;
    white-space: nowrap;
    &:visited{
      color: inherit;
    }
    span{
      display: flex;
      align-items: center;
    }
    &:last-child {
      overflow: hidden;
      text-overflow:  ellipsis;
      display: flex;
    }
  }
  & > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
