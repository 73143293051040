.tile-view {
  width: 100%;
  height: 100%;

  .header {
    border-bottom: 1px solid var(--color-divider);
    border-top: 1px solid var(--color-divider);
    background: var(--background-plate-nested);
    color: var(--color-title-alpha);

    font-weight: 700;

    span {
      display: flex;
      padding: 8px 24px;
      font-size: 12px;
      line-height: 2;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }
}


.tile-item{
  width: 327px;
  margin-right: 16px;
  margin-bottom: 16px;

  .text{
    flex: 1;
    height: 100%;
    align-items: center;
    display: flex;
    cursor: pointer;
    max-width: 80%;

    & > span:not(:only-child) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }


  &__version{
    display: inline-flex;
    padding: 4px 10px;
    border-radius: 3px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    background: #DFE1E6;
    margin-left: .5em;
  }

  .k-card-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-plate-nested);
    font-size: 14px;
    padding: 10px 10px 10px 14px;
    border-bottom: 1px solid var(--color-divider-nested);
    margin: 0;
  }

  .k-card-body{
    padding: 0 8px;
    cursor: pointer;

    .item{
      color: var(--text-secondary);
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      border-bottom: 1px solid var(--color-divider-nested);
      &:last-child{
        border-bottom: none;
      }

      & > div:first-of-type {
        margin-right: 1.5em;
      }
    }

  }
}
