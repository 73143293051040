.edit-comment-dialog {
  margin-top: 10px;

  .dialog-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding: 16px;

  }

  form {
    width: 348px;
  }

  .dialog-content {
    padding: 0 16px;

    .dialog-input > div {
      padding: 8px 8px 16px;
      background: var(--background-plate-nested);
      & fieldset {
        border: 1px solid var(--border-color);
      }
    }

    textarea{
      resize: vertical;
    }
  }

  .form-field{
    position: relative;

    .count{
      position: absolute;
      bottom: 1px;
      right: 3px;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: var(--text-secondary);
      &.error{
        color: var(--text-danger)
      }
    }
  }

  .form-buttons{
    display: flex;
    gap: 12px;
    justify-content: flex-end ;
    padding: 16px;
  }


}

