.toast-item{
  width: 356px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0px rgba(0,0,0,.1);
  box-shadow: 0 1px 4px 0px rgba(0,0,0,.1);
  margin-top: 1rem;
  border-left: 6px solid transparent;
  font-size: 14px;

  .content{
    display: flex;
    flex-direction: column;
    flex: 1;
    &:before {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  .close{
    cursor: pointer;
  }

  &.toast-success{
    color: #18B682;
    border-color: #18B682;
    background: #E5F8F0;

    .content:before{
      content: 'Операция завершена успешно';
    }
  }

  &.toast-error{
    color: #FE6363;
    border-color: #FE6363;
    background: #FDE8E7;

    .content:before{
      content: 'Произошла ошибка';
    }
  }

  &.toast-warning{
    color: #CD9200;
    border-color: #CD9200;
    background: #FFF3D4;

    .content:before{
      content: 'Внимание';
    }
  }

  &.toast-info{
    color: #0A87C0;
    border-color: #0A87C0;
    background: #DFF5FF;

    .content:before{
      content: 'Информация';
    }
  }

}


